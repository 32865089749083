import React, { useState } from "react"
import _ from "lodash"
import Swal from "sweetalert2"
import ReCAPTCHA from "react-google-recaptcha"

import Image from "../image"

const ContectUsMain = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState({})
  const [state, setState] = React.useState({
    callback: "not fired",
    value: "",
    expired: false,
  })

  const _reCaptchaRef = React.createRef()

  const handleSubmit = event => {
    event.preventDefault()

    if (!validate()) return

    let body = new FormData()
    body.append("name", name)
    body.append("email", email)
    body.append("message", message)

    fetch("https://api.shopbizservices.com/index.php", {
      method: "POST",
      mode: "no-cors",
      "Access-Control-Allow-Origin": "*",
      body,
    })
      .then(() => {
        setEmail("")
        setMessage("")
        setName("")
        Swal.fire({
          title: "Thank You",
          text: "We will contact you soon.",
          icon: "success",
          confirmButtonText: "Okay",
        })
      })
      .catch(() => {
        Swal.fire({
          title: "Error",
          text: "Something went wrong at our end. Please try again.",
          icon: "error",
          confirmButtonText: "Okay",
        })
      })
  }

  const validate = () => {
    const errors = {}
    if (_.isEmpty(name)) errors.name = "name can not be empty"
    if (!email.includes("@")) errors.email = "Please enter a valid email"
    if (_.isEmpty(email)) errors.email = "email can not be empty"
    if (_.isEmpty(message)) errors.message = "name can not be empty"
    if (_.isEmpty(state.value))
      errors.recaptcha = "please verify that you are not a robot"
    setError(errors)
    return _.isEmpty(errors)
  }

  const remvoeError = ({ target: { name } }) => {
    setError({ ...error, [name]: "" })
  }

  const handleChange = value => {
    setError({ ...error, recaptcha: "" })
    setState({ value })
    if (value === null) setState({ expired: true })
  }

  const asyncScriptOnLoad = () => {
    setState({ callback: "called!" })
  }

  return (
    <>
      <div className="container contact-page">
        <div className="row d-flex justify-content-center align-items-center flex-column py-4 mt-5">
          <div className="col d-flex justify-content-center">
            <Image className="card-icon " src="get-in-touch.png" />
          </div>
          <div className="col d-flex justify-content-center">
            <p className="main-heading ">Get In Touch</p>
          </div>
        </div>
        <div className="row">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3565.5829499978554!2d-80.26707328487751!3d26.661832383236234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d92ff01b348051%3A0x6e2a7d3a22d12758!2s1300+Corporate+Center+Way+%23105c%2C+Wellington%2C+FL+33414!5e0!3m2!1sen!2s!4v1479425071387"
            id="map"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            title="Shop-boz-map"
          />
        </div>
        <div className="row contact-form-section d-flex justify-content-between mb-5 flex-row">
          <form
            className=" col-xs-12 col-md-6 pt-4 form"
            onSubmit={handleSubmit}
          >
            <p className="contact-us-form-heading">Send us a Message</p>
            <p for="Name" className="label">
              Your name
            </p>
            <input
              className="input"
              type="text"
              name="name"
              onFocus={remvoeError}
              value={name}
              onChange={({ target: { value } }) => setName(value)}
            />
            {error.name && (
              <span className="text-danger">
                <small>{error.name}</small>
              </span>
            )}
            <p for="mail" className="label">
              Your email
            </p>
            <input
              className="input"
              type="email"
              name="email"
              value={email}
              onFocus={remvoeError}
              onChange={({ target: { value } }) => setEmail(value)}
            />
            {error.email && (
              <span className="text-danger">
                <small>{error.email}</small>
              </span>
            )}
            <p for="message" className="textarea-label">
              Message
            </p>
            <textarea
              className="textarea-field"
              name="message"
              rows="5"
              value={message}
              onFocus={remvoeError}
              onChange={({ target: { value } }) => setMessage(value)}
            />
            {error.message && (
              <span className="text-danger">
                <small>{error.message}</small>
              </span>
            )}
            <div className="form-group" style={{ marginTop: "2%" }}>
              <ReCAPTCHA
                style={{ display: "inline-block" }}
                theme="light"
                ref={_reCaptchaRef}
                sitekey="6LfhPG4aAAAAAHWNFnw6gtOLlkj_QhaS44h7bs3w"
                onChange={handleChange}
                asyncScriptOnLoad={asyncScriptOnLoad}
              />
              <br />
              {error.recaptcha && (
                <span className="text-danger">
                  <small>{error.recaptcha}</small>
                </span>
              )}
            </div>
            <button type="submit" className="btn send-button">
              Send
            </button>
          </form>
          <div className="col-xs-12 col-md-6 contact-main-details px-5 py-4">
            <div className="row d-flex flex-column">
              <h1>Contact Details</h1>
              <div className="contect-main-address d-flex">
                <svg
                  className="address-icon"
                  width="28"
                  height="28"
                  viewBox="0 0 10 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.9 0C2.1938 0 0 2.1938 0 4.9C0 8.4 4.9 14 4.9 14C4.9 14 9.8 8.4 9.8 4.9C9.8 2.1938 7.6062 0 4.9 0ZM4.9 6.65C3.9333 6.65 3.15 5.8667 3.15 4.9C3.15 3.9333 3.9333 3.15 4.9 3.15C5.8667 3.15 6.65 3.9333 6.65 4.9C6.65 5.8667 5.8667 6.65 4.9 6.65Z"
                    fill="white"
                  />
                </svg>

                <p>
                  1300 CORPORATE CENTER WAY SUITE # 105C WELLINGTON, FL 33414
                </p>
              </div>
              <div className="contect-main-mail d-flex">
                <svg
                  className="mail-icon"
                  width="30"
                  height="28"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0L0 5.09004L3.80625 8.89766L11.2 2.8L5.10234 10.1937L8.90996 14L14 0Z"
                    fill="white"
                  />
                </svg>

                <a
                  href="mailto:hello@shopbizservices.com"
                  className="ml-2 text-white"
                >
                  hello@shopbizservices.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContectUsMain
